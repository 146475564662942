import React from 'react'

const Grievances = () => {
  return (
    <section class="policy">
        <div class="container">
            <div class="policy_area">
                <div class="policy_area_col">
                    <div class="policy_heading">
                        <h2>Grievances<span> Redressal</span></h2>
                    </div>
                    <p>DETAILS:<br/>Hasim Shaikh
                    If you have any grievance with respect to any of the Content, Title, Age rating, Synopsis, Parental control feature etc. available 
                        on our Kaddu App, you can register a complaint with our Grievance Redressal Officer on below mentioned details:</p>
                    <p>Grievance Redressal Officer: Namrata Singh <br/>
                    Email id: kiran.pawar@Kaddumovies.app</p>
                    <p>One can raise a complaint by contacting our Grievance Redressal officer by emailing at kiran.pawar@Kaddumovies.app Upon receipt of the complaint the same shall be acknowledged within a period of 24 hours. Our Grievance Redressal
                         team headed by our Grievance Redressal Officer will resolve the complaint within 15 days from the date of receipt of complaint.</p>
                </div>
            </div>
        </div>      
    </section>
  )
}

export default Grievances