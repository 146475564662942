/*global FB*/
import { createContext, useContext} from "react";
import axios from "axios";
import {
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import Instance from "../services/Base";
import {Access, Profile} from "../services/Access"
import { begin_checkout,checkout_paytm,checkout_stripe } from "./Eventlogger";
import { revenue,login,purchase} from "./Eventlogger";
import { fbLogin,getFacebookLoginStatus } from "../utils/FacebookSdk";
//initDB(DBConfig);
import Dexie from 'dexie'
import { useLiveQuery } from "dexie-react-hooks";
const userAuthContext = createContext();
const db = new Dexie('kaddu');
db.version(1).stores({
  contents:'id,content',
  upcomings:"++id,upcoming",
  subscriptions:"++id,subscription",
})


export function UserAuthContextProvider({ children }) {
  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

//login using email - kaddu
const loginwithemail = async (data) => {
  console.log(data)
    return Instance.post('/login',data)
    .then( async res =>{
      console.log(res)
      let xyz = res.data;
           if(xyz.error){
            //errro
           }else{
            localStorage.setItem('access-token',xyz.data.accessToken)
            localStorage.setItem('id',xyz.data.id)
            if(xyz.data.subscription_type === 'paid'){
              localStorage.setItem('user','paid')
            }
           }
        return xyz ;
  })
}

// const googleSignIn=async()=>{
//   const googleAuthProvider = new GoogleAuthProvider();
//   const che = googleAuthProvider.addScope("email");
//   return signInWithPopup(auth, che)
//   .then(async res=>{
//     const result = await register_mobile(Array.isArray(res.user.providerData) ? res.user.providerData[0] :  res.user.providerData,res.providerId )
//     return result;
//   });
// }
const googleSignIn= (user)=> {
  if(user){
  axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: 'application/json'
            }
        })
        .then((res) => {
          console.log("google SignIn Res",res)
          if(res.status === 200){
            register_mobile(res.data,'google.com')
          }
        })
        .catch((err) => console.log(err));
  }
}

const handleGoogleSignIn = async (e) => {
  e.preventDefault();
  try {
    await googleSignIn()
    .then( res=>{
      register_mobile(res.user,res.providerId)
    })
    
  } catch (error) {
    console.log(error.message);
  }
};


function facebookSignIn() {
  const facebookAuthProvider = new FacebookAuthProvider();
  return signInWithPopup(auth, facebookAuthProvider);
}

const handleFacebookSignIn=(res)=> {
  console.log(res)
  // e.preventDefault();
  // try {
  //   await facebookSignIn()
  //   .then(res=>{
  //     console.log(res);
  //     // register_mobile(Array.isArray(res.user.providerData) ? res.user.providerData[0] :  res.user.providerData,res.providerId )
  //   })
    
  // } catch (error) {
  //   console.log(error.message);
  // }
   
};


const register_mobile = async(user,providerId)=>{
  var permission;
  var num = user.phoneNumber ? user.phoneNumber : null;
  var ne ="";
  var mail = user.email;
  var profile = user.picture;
  if(providerId === 'google.com'){
    ne = user.given_name;
    permission = ["google"]
  }else if(providerId === 'facebook.com'){
    ne = user.name;
    permission = ['facebook']
  }else{
    permission = ["phone"]
  }
  const data = {
    'name': ne,
    'email':mail,
    'password':'',
    'permission':permission,
    'mobile':num,
    'profilePic':profile,
    'socialNetworkingId':user.id,
  }
  const response = await Signup(data)
  return response;
}
//registration API call
const Signup= async(data)=>{
  return Instance.post('/registrationNew',data)
  .then( async res =>{
    if(res.data.error === false){
      if(res.data.data.subscription_enddate){
      localStorage.setItem('subscribed',true)
      }
      localStorage.setItem('id',res.data.data.userId)
      localStorage.setItem('access-token',res.data.data.accessToken)
      refresh()
    // 
    }
    return res.data
  }).catch(err =>{
    console.log(err)
  })
}



const mobileactivation = async(data) =>{
  return Instance.post('/activateMobile',data)
  .then(async res=>{
    const response= await decryptData(res.data)
    return response
  })
}
const refresh=()=>{
  setTimeout(()=>{
    window.location.reload()
},1000)
}

//logout api call
const logout=()=>{
  signOut(auth)
  localStorage.clear();
  //refresh()
}

const forgetpassword = async(data)=>{
  return Instance.post('/forgetPassword',data)
  .then(async res => {
    const resp = await decryptData(res.data)
    return JSON.parse(resp)
  })
}


const VerifyPasswordOtp=async (data)=>{
  return Instance.post('/verifyForgetPasswordOtp',data)
  .then(async res=>{
    const resp = await decryptData(res.data)
    return JSON.parse(resp)
  })
  .catch(err =>{
    console.log(err)
  })
}

const player=async (data)=>{
  return Profile.post('/getplayInfo',data)
  .then(res =>{
    const en = decryptData(res.data)
    //const final = JSON.parse(en)
    return en
  }).catch(err =>{
    console.log(err)
  })
}
const AddData_filtered = async(arr,name)=>{
  if(name === 'content'){
    arr.map(x=>{
      return db.contents.add({
        id: x.id,
        test:x.test
      })
    })
  }
  if(name === 'upcoming'){
    arr.map(x=>{
      return db.upcomings.add({
        id: x.id,
        upcoming:x.upcoming
      })
    })
  }
  if(name === 'subscription'){
    arr.map(x=>{
      return db.subscriptions.add({
        id: x.id,
        subscription:x.subscription
      })
    })
  }
  //Refresh()
}

const Delete_data=async(arr,name)=>{
  if(name === 'content'){
    arr.map(x=>{
      return db.contents.delete(x)
    })
  }
  if(name === 'upcoming'){
    arr.map(x=>{
      return db.upcomings.delete(x)
    })
  }
  if(name === 'subscription'){
    arr.map(x=>{
      return  db.subscriptions.delete(x)
    })
  }
  //Refresh()
}

//when component mounted
const AddData =async()=>{
  // const result= await getContent();
  // const packages = await getPackage();
  // const upcoming = await getUpcoming();
  // console.log(result)
  // console.log(packages)
  // //adding content
  // result.map(x=>{
  //     return db.contents.add({
  //       id:x._id,
  //       test:x
  //     })
  // })
  // packages.data.map(x=>{
  //   return db.subscriptions.add({
  //     id:x._id,
  //     subscription:x
  //   })
  // })

  // upcoming.data.map(x=>{
  //   return db.upcomings.add({
  //     id:x._id,
  //     upcoming:x
  //   })
  // })

  // setTimeout(()=>{
  //  // refresh()
  // },[500])
}


const UpdateData =async(id,value,name)=>{
  if(name === 'content'){
    await db.contents.update(id,{test:value})
  }
  if(name === 'upcoming'){
    await db.upcomings.update(id,{upcoming:value})
  }
  if(name === 'subscription'){
    await db.subscriptions.update(id,{subscription:value})
  }
}

const allContents = useLiveQuery(() => db.contents.reverse().toArray(), []);
const allUpcomings = useLiveQuery(() => db.upcomings.toArray(), []);
const allSubscriptions = useLiveQuery(() => db.subscriptions.toArray(), []);
if (!allContents) return null
if (!allUpcomings) return null
if (!allSubscriptions) return null

//decryption code for contents
async function decrypt(encryptedText, keyObject) {
  const textDecoder = new TextDecoder("utf-8");
  const decryptedText = await crypto.subtle.decrypt({ name: 'AES-CBC', iv: converthextToByteBuffer(keyObject.iv) }, await genearteKey(keyObject.key), encryptedText);
  return textDecoder.decode(decryptedText);
}

async function decryptData(encryptedObject) {
  const keyObject={'key':"748B63951C935355D82975EDB8EB7FE3AABCD8252BEBD6644A6A0518C7FB4A38",
'iv':"AF59284766DA558FDB1EDC8BE4DD198C",
}
	const decryptedObject = await decrypt(encryptedObject, keyObject);
	return decryptedObject;
}

async function genearteKey(key){
  return crypto.subtle.importKey("raw", converthextToByteBuffer(key),"AES-CBC",false,["decrypt"])
}

function converthextToByteBuffer(hex){
var typedArray = new Uint8Array(hex.match(/[\da-f]{2}/gi).map(function (h) {
   return parseInt(h, 16)
}))
return typedArray.buffer

}

const getContent = async ()=>{
  return Instance.get('/getContents?index=0&&limit=50')
  .then( async res=>{
    return res.data.data;
  })
}

const Fetch_profile =async(userId)=>{
  if(userId){
    const data = {
      'userId':userId
    }
  
  return Profile.post('/getProfile',data)
  .then(async res=>{
    return res
  })
}
}
const getPackages=async ()=>{
  const data = {
    "platform":"Android Phone",
    "countryCode":"in"
  }
  return Instance.post('/getAllPackages',data)
  .then(async res =>{
    return res
})
}
const getUpcoming=async()=>{
  return Instance.post('/getUpcoming')
  .then(async res =>{
   // return res
    return res
})
}


const ResetPassword =async (data)=> {
  return Instance.post('/resetPassword',data)
  .then( async res => {
   const reset= await decryptData(res.data)
   return JSON.parse(reset) 
  })
}
const geolocation= async()=>{
  const request = await fetch("https://ipapi.co/json/")
  if(request){
    const json = await request.json()
    return json   
  }

}
const ChangePassword = async(data)=>{
  return Profile.post('/changePassword',data)
  .then(async res=>{
    const pass = await decryptData(res.data)
    return JSON.parse(pass)
  })
}

const Support = async (data)=>{
  return Instance.post('/createSupportTicket',data)
  .then(async res =>{
    const resp=await decryptData(res.data)
    return JSON.parse(resp)
  })
}
const cc = localStorage.getItem('CC')
const setCountryCode=()=>{
  if(!cc){
    return axios.get('https://ipapi.co/json/').then((response) => {
      localStorage.setItem('CC',response.data.country_code)
      return response
    }).catch(err=>{
      console.log(err)
    })
  }
}
function handleSessionResponse(response) {
  FB.logout(response);
}
const facebookLogout=()=>{
  FB.getLoginStatus(handleSessionResponse);
}
async function checkLoginState(from) {  
  const status = await getFacebookLoginStatus();
  if(status && status.status !== "connected"){
    if(from !== "home"){
      testAPI();
    }
  }else{
    facebookLogout()
  }
}
const testAPI = async() => {
  const response = await fbLogin();
  console.log(response);
  if (response.status === "connected") {
    FB.api('/me?fields=email,name,picture',function(resp) {
      if(resp){
        const obj  = {
          email:resp.email,
          name:!resp.name?'':resp.name,
          id:resp.id,
          picture:resp.picture?.data.url
        }
        console.log(obj)
        if(!resp.email){
          //toaster("Try again later!","error")
        }else{
          register_mobile(obj,'facebook.com')
         // handleFacebookSignIn(obj)
        }
      }
    });
  }
};
const fetch=async ()=>{
//   if(!cc){setCountryCode()}
//   const con = await getContent()
//   const upcoming1 = await getUpcoming()
//   console.log(con)
//   if (con===undefined){
//       console.log("badgateway")
//   }
//  // ---**All Contents - Update Code**---//
//  console.log(allContents)
//  if(allContents.length > 0 )
//  {
//      //checking length of each array --- Indexed DB Data
//      const content = allContents.length
//      //---**Contents**---//
//      if(content === con.content.length){
//        for(var i=0;i<con.content.length;i++){
//         for(var j=0;j<content;j++){
//          if(con.content[i]._id=== allContents[j].id){
//          const indexed = allContents[j].test
//          const original = con.content[i]
//          if(indexed.title !== original.title || indexed.director !== original.director ||
//            indexed.production !== original.production || indexed.releaseDate !== original.releaseDate ||
//            indexed.trailerFileUrl !== original.trailerFileUrl ||  indexed.status !== original.status ||
//            indexed.popularity !== original.popularity ||  indexed.mainGenre !== original.mainGenre ||
//            indexed.ageGroup !== original.ageGroup || indexed.landscapePosterId !== original.landscapePosterId ||
//           indexed.portraitPosterId !== original.portraitPosterId ){
//           UpdateData(indexed._id, original,'content' )
//          }

         
//          //checking seasons array length
//          if(indexed.seasons.length !== original.seasons.length || indexed.genres.length !== original.genres.length){
//           UpdateData(indexed._id, original,'content' )
//           break
//          }

//          //if webseries then checking episode name and other fields
//          if(indexed.mainGenre !== ''){
//            //console.log('executing webseries -->', indexed.title)
//            indexed.seasons.map((x,index) => {
//              if(x.episodes.length !== original.seasons[index].episodes.length){
//                //console.log('please update content episode changed length not matching')
//                UpdateData(indexed._id, original,'content' )
//              }
             
//              if(x.episodes.length === original.seasons[index].episodes.length){
//                const ss = original.seasons[index]
//                x.episodes.map((y,index)=>{
//                  //console.log('season -->',x.seasonName,'episode -->', y.name)
//                 if(y.name !== ss.episodes[index].name || y.description !== ss.episodes[index].description || 
//                  y.duration !== ss.episodes[index].duration || y.landscapePosterId !== ss.episodes[index].landscapePosterId){
//                    //console.log('update episode name ->',y.name , 'updated value is ->',ss.episodes[index].name )
//                    UpdateData(indexed._id, original,'content' )
//                    }
//                    const eps = ss.episodes[index]
//                    if(y.cast.length !== eps.cast.length){
//                      //UpdateData(indexed._id, original,'content' )
//                    }else{
//                      y.cast.map((z,index)=>{
//                        if(z !== eps.cast[index]){
//                          UpdateData(indexed._id, original,'content' )
//                        }
//                      })
//                    }
//                })
//              }
//            })
           
//          }
//          if(indexed.genres.length === original.genres.length){
//            indexed.genres.map((x,index)=>{
//              if(x.genreId !== original.genres[index].genreId){
//                UpdateData(indexed._id, original,'content' )
//              }
//            })
//          }


//          //boolean values censor freelyAvailable videoAvailable
//          if(indexed.censor !== original.censor || indexed.freelyAvailable !== original.freelyAvailable){
//              UpdateData(indexed._id, original,'content' )
//          }
       
//          }
//         }
//        }
//      }else{
//       //adding data if not available in the indexed db
//      const arr_handler_add=[]
//      const allContents1 = allContents.map(x => x.id)
//      const real = con.content.map(x=>x._id)
//          if(allContents.length < con.content.length){
//              con.content.map(x=>{
//                  if( allContents1.indexOf(x._id) === -1 ){
//                    arr_handler_add.push({id:x._id,test:x})
//                  }
//              })
//              AddData_filtered(arr_handler_add,'content')
//           }
//          //deleting data which are deleted from the database
//          const arr_handler_delete=[]
//          if(allContents.length > con.content.length){
//            allContents.map(x=>{
//              if(real.indexOf(x.id) === -1){
//                arr_handler_delete.push(x.id)
//              }
//            })
//             Delete_data(arr_handler_delete,'content')
//           }  
//      }       
//  }

//  // ---**Upcoming Contents - Update Code**---//
//  const upcoming = allUpcomings.length;
//  const upcon = upcoming1.data ;
//  if(upcoming > 0 || upcon.length > 0){
//      const allUpcomings1 = allUpcomings.map(x => x.id)
//      const real1 = upcon.map(x=>x._id)
//      if( upcoming !== upcon.length){
//        //two array to check data status
//        //indexed data is more than the present data ----- delete operation -----
//        if(allUpcomings.length > upcon.length){
//          //deleteing the data if it is not present in the real database (present in the indexed db )
//          const arr_handler_delete_upcoming = []
//          allUpcomings.map(x=>{
//            if(real1.indexOf(x.id) === -1){
//              arr_handler_delete_upcoming.push(x.id)
//             Delete_data(arr_handler_delete_upcoming,'upcoming')
//            }
//          })
//        }
//        //indexed data for adding new upcoming content ----- add operation ------
//       const arr_handler_add_upcoming = []
//       upcon.map(x=>{
//           if( allUpcomings1.indexOf(x._id) === -1 ){
//             arr_handler_add_upcoming.push({id:x._id,upcoming:x})
//             AddData_filtered(arr_handler_add_upcoming,'upcoming')
            
//           }
//       })
//      }else{
//        //when both the array length is equal
//        for(var i=0;i<upcon.length;i++){
//          for (var j=0;j<allUpcomings.length;j++){
//            if(upcon[i]._id === allUpcomings[j].id){
//              var x = allUpcomings[j].upcoming
//              if(x.title !== upcon[i].title || x.share_link !== upcon[i].share_link || x.release_date !== upcon[i].release_date ||
//                x.description !== upcon[i].description || x.landscape_poster_id !== upcon[i].landscape_poster_id || x.part_name !== upcon[i].part_name || 
//                x.media_url.web !== upcon[i].media_url.web ||  x.media_url.mobile !== upcon[i].media_url.mobile ||  x.media_url.common !== upcon[i].media_url.common){
//                 UpdateData( x._id , upcon[i] , 'upcoming')
//              }
//              //matched contents only 

//            }
//          }
         
//        }
//      }
//  } //end here upcoming
}

const Check_subscription=async ()=>{
  // const package1 = await getPackage()
  // // ---**Subscriptions Packages - Update Code**---//
  // if(allSubscriptions.length >0){
  //   const subs = package1.data ;
  //   const allSubscriptions1 = allSubscriptions.map(x => x.id)
  //   const real1 = subs.map(x=>x._id)
  //   if( allSubscriptions.length !== subs.length){
  //     //two array to check data status
  //     //indexed data is more than the present data ----- delete operation -----
  //     if(allSubscriptions.length > subs.length){
  //       //deleteing the data if it is not present in the real database (present in the indexed db )
  //       const arr_handler_delete_upcoming = []
  //       allSubscriptions.map(x=>{
  //         if(real1.indexOf(x.id) === -1){
  //           arr_handler_delete_upcoming.push(x.id)
  //          Delete_data(arr_handler_delete_upcoming,'subscription')
  //         }
  //       })
  //     }
  //     //indexed data for adding new upcoming content ----- add operation ------
  //    const arr_handler_add_upcoming = []
  //    subs.map(x=>{
  //        if( allSubscriptions1.indexOf(x._id) === -1 ){
  //          arr_handler_add_upcoming.push({id:x._id,subscription:x})
  //          AddData_filtered(arr_handler_add_upcoming,'subscription')
  //        }
  //    })
  //   }else{
  //     //when both the array length is equal
  //     for(var i=0;i<subs.length;i++){
  //       for (var j=0;j<allSubscriptions.length;j++){
  //         if(subs[i]._id === allSubscriptions[j].id){
  //           var x = allSubscriptions[j].subscription
  //           if(x.status !== subs[i].status || x.platform !== subs[i].platform || x.period !== subs[i].period ||
  //             x.name !== subs[i].name || x.date !== subs[i].date || x.price.IN.value !== subs[i].price.IN.value){
  //              UpdateData( x._id , subs[i] , 'subscription')
  //           }
  //           if(x.description.length === subs[i].description.length){
  //             x.description.map((y,index)=>{
  //               if(y !== subs[i].description[index]){
  //                UpdateData(x._id, subs[i],'subscription' )
  //               }
  //             })
  //           }
  //           if(x.description.length !== subs[i].description.length){
  //            // UpdateData(x._id, subs[i],'subscription' )
  //           }
  //           //matched contents only 

  //         }
  //       }
        
  //     }
  //   }
  // }
}

//for creating order (using stripe nad paytm)
const createOrder=(param,id,name,packageId,userId)=>{
   //setpaymentId(id
  const filter_price = param[0].subscription
  const data = {
    amount: filter_price.price.IN.value,
    currency: filter_price.price.IN.currency,
    package_id:packageId,
    platform: filter_price.platform,
    userId:userId,
    country_code :'IN',
    gateway:name
  }
  
  begin_checkout(filter_price.price.IN.value ,filter_price.price.IN.currency)
  if( name === 'stripe' ){
    checkout_stripe(filter_price.price.IN.value , filter_price.price.IN.currency)
      return Profile.post('/createOrder',data)
      .then(async res=>{
        //console.log(res)
        return res
      })
  }else if( name === 'paytm'){
    checkout_paytm(filter_price.price.IN.value , filter_price.price.IN.currency)
      return Profile.post('/createOrder',data)
      .then(async res=>{
        return res
      })
  }else{
      console.log('no gateway selected')
  }
}

//update order
const updateOrder=async (orderId,userId,paymentId,status,message,gateway,amountobj)=>{
  const us = localStorage.getItem('user')
  //for successfull payment
  if(gateway  === 'stripe'){
    if(status === 'true'){
      const rr = await confirmOrder(orderId,userId,amountobj)
      return rr
    }
    //for failed transaction
    else{
      const data= {
        'order_id':orderId,
        'error_id':message
      }
  
      try {
        const res_1 = await Profile.post('/updateOrder', data);
        const dcr_1 = decryptData(res_1.data);
        //const final_1 = JSON.parse(dcr_1);
        return dcr_1;
      } catch (err) {
        if (err.response.status === 403) {
          localStorage.clear();
        }
      }
    }
  }else if(gateway === 'paytm'){
    //for paytm callback
    if(status === 'true'){
      //purchase(orderId , paymentId)
      //setflag(true)
      const data= {
        'orderId':orderId,
        'userId':userId,
        'paymentId':paymentId
      }
      
      const res = await Profile.post('/updateOrder', data);
      const dcr = decryptData(res.data);
      dcr.then(res=>{
        const obj = JSON.parse(res)
        if(obj.code === 1340){
          purchase(orderId , paymentId)
          revenue(amountobj)
          if(!us){
            localStorage.setItem('user','paid')
          }
        }
      })
      return dcr;
    }
    //for failed transaction
    else{
      const data= {
        'order_id':orderId,
        'error_id':message
      }
  
      try {
        const res_1 = await Profile.post('/updateOrder', data);
        const dcr_1 = decryptData(res_1.data);
        //const final_1 = JSON.parse(dcr_1);
        return dcr_1;
      } catch (err) {
        if (err.response.status === 403) {
          localStorage.clear();
        }
      }
    }
  
  }
}


const confirmOrder=async (orderId,userId,amountobj)=>{
  const us = localStorage.getItem('user')
  const param = {
    'orderId':orderId,
    'userId':userId
  }
  try {
    const res = await Profile.post('/confirmOrder', param);
    const dcr = decryptData(res.data);
    //const final = JSON.parse(dcr);
    dcr.then(res=>{
      const data = JSON.parse(res)
      if(data.code === 1340){        
        revenue(amountobj)
        if(!us){
          localStorage.setItem('user','paid')
        }
      }
      return data
    })
    return dcr;
  } catch (err) {
    if (err.response.status === 403) {
      localStorage.clear();
    }
  }
}


const checkvalidity=async()=>{
    const id = localStorage.getItem('id')
    const data = {
      userId: id
    }
    return Profile.post('/isValid',data)
    .then( async res=>{      
      const enc = decryptData(res.data)
      const data = await enc
      const  obj = JSON.parse(data)
      if( ( obj.code !== 1601 && obj.data.subscription_type !== 'paid' ) || obj.code === 1002){
        localStorage.clear()
        refresh()
      }
      return obj
    }).catch(err=>{
      console.log(err)
    })
}

const verify=async (token)=>{
  const params = {
    token:token
  }
  return Instance.post('/verifyrecaptcha',params)
  .then(res=>{
    return res
  }).catch(err=>{
    console.log(err)
  })
}
const verifyqr=async(token,userId,accesstoken,socketId)=>{
  var params = {
    token:token,
    userId:userId,
    accesstoken:accesstoken,
    socketId:socketId
  }
  return Access.post('/qr/verify',params).then(res=>{
    return res
  }).catch(err=>{
    return err
  })
}
const fetchuserdetails=async (userId)=>{
  const data = {
    'userId':userId
  }
  return Access.post('/getProfile',data)
  .then(async res=>{  
      return res
  })
}
  return (
    <userAuthContext.Provider
      value={{
        Check_subscription,
        checkvalidity,
        googleSignIn,
        setUpRecaptha,
        checkLoginState,
        loginwithemail,
        getContent,
        handleFacebookSignIn,
        handleGoogleSignIn,
        Signup,
        logout,
        register_mobile,
        forgetpassword,
        ResetPassword,
        ChangePassword,
        VerifyPasswordOtp,
        AddData,
        mobileactivation,
        allContents,
        allUpcomings,
        allSubscriptions,
        Support,
        geolocation,
        Fetch_profile,
        fetch,
        createOrder,
        updateOrder,
        confirmOrder,
        player,
        setCountryCode,
        refresh,
        verify,
        verifyqr,
        decryptData,
        getPackages,
        getUpcoming,
        fetchuserdetails
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}