import React,{useState,useEffect,useRef} from 'react'
import '../../App.css';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { useUserAuth } from '../../context/UserAuthContext';
const Contactus = () => {
    const [number,setnumber]=useState('')
    const [name,setname]=useState('')
    const [email,setemail]=useState('')
    const [flag,setflag]=useState(true)
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [description,setdescription]=useState('');
    const[err,seterr]=useState('')
    const [selectedOption,setselectedOption]=useState({value:'Subject*'})
    const [message,setmessage]=useState('')
    const { Support , verify} = useUserAuth();
    const recap = useRef()
    useEffect(() => {
        
        {/*const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
     
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
     
          if (isScriptExist && callback) callback();
        }
     
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded! Re-captcha");
          grecaptcha.ready(function() {
            grecaptcha.execute('reCAPTCHA_site_key', {action: 'submit'}).then(function(token) {
                // Add your logic to submit to your backend server here.
                console.log(token)
            });
          });
        });*/}
      }, []);
      const onResolve=(e)=>{
        console.log('resolved')
        
       // const result = await Support(data)
        //console.log(result)
        //if(result.error==false){
          //  seterr("Your message has been sent !")
        //}
        //else{
          //  seterr("An error occurred.")
        //}
    }
    const contact =async (e)=>{
        e.preventDefault();
        if(flag){
            const data = {
                "mobile": number,
                "issuetype" : selectedOption.value,
                "email": email,
                "name":name,
                "description":description,
            }
        const result = await Support(data)
        if(result.error==false){
        seterr("Your message has been sent !")
        }
        else{
        seterr("An error occurred.")
        }
        }else{
        seterr("Re-captcha not resolved, please try again")
        }
    }
    const handleselect=(e)=>{
        setselectedOption({value:e.target.value})
       // recap.current.execute();
    }
  

  return (
    <div className='container contactus'>
        <div className='row'>
        <div className='col-md-4'>
               
               <h1><span>CONTACT</span> US</h1>
               <p>Reach out to us for any queries, suggestions or feedback!</p>
               <div className='company mt-2 p-0'>
               <h2>Kaddu Entertainment Pvt. Ltd.</h2>
               <div className='row'>
                   <div className='col-md-2'>
                       <p>Email </p>
                   </div>
                   <div className='col-md-1'><p>:</p></div>
                   <div className='col-md-9'>
                       <p> kadduappwork@gmail.com</p>
                   </div>
               </div>
               <div className='row mt-0'>
                   <div className='col-md-2'>
                       <p>Contact:</p>
                   </div>
                   <div className='col-md-1'><p>:</p></div>
                   <div className='col-md-9'>
                       <p>+91-7304566421</p>
                   </div>
               </div>
               <div className='row mt-0'>
                   <div className='col-md-2'>
                       <p>Address:</p>
                   </div>
                   <div className='col-md-1'><p>:</p></div>
                   <div className='col-md-9' >
                       <p>257, Adarsh Nagar Behind Off Zubin wine Shop, New Link Road, Andheri West, Mumbai-400053.</p>
                   </div>
               </div>
               </div>
               
           </div>
            <div className='col-md-8'>
            <p style={{'fontSize':'20px' ,'marginBottom':'20px','color':'orange'}}>{message}</p>
                <form onSubmit={contact}>
                    <div className="mb-3">
                        <input type="text" placeholder='Full Name*' onChange={(e)=> setname(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-4">
                        <input type="email" placeholder='Email Address*'  onChange={(e)=> setemail(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-4">
                    <PhoneInput
                            
                            className='phone rounded'
                              defaultCountry="IN"
                              value={number}
                              onChange={setnumber}
                              maxLength='12'
                              placeholder="Enter Phone Number *"    
                              
                          />
                    </div>
                     
                    <div className="mb-3">
                    <select onChange={handleselect} >
                        <option value="Payment Issues">Payment Issues</option>
                        <option value="Subscription Issues">Subscription Issues</option>
                        <option value="Video Plaback Issues">Video Plaback Issues</option>
                        <option value="Business Purpose">Business Purpose</option>
                        <option value="Other Issues">Other Issues</option>
                    </select>
                    </div>
                    <div className="mb-3">
                        <textarea placeholder='Tell us more about your issue*'  onChange={(e)=> setdescription(e.target.value)} rows="6" />
                    </div>
                    
                    {flag  ?
                        <button type="submit" className="btn login p-3 m-0" style={{'backgroundColor':'red','color':'#fff','float':'right'}}>Send Message</button>:
                        <button type="submit" disabled className="btn login p-3 m-0" style={{'backgroundColor':'red','color':'#fff','float':'right'}}>Send Message</button>
                    }
                    

                    <div className="message" style={{'color':'#fff','fontSize':'18px','marginTop':'10px'}}>{err}</div>
                    
                </form>
            </div>
        </div>
       
    </div>
  )
}

export default Contactus