/*global FB*/
export const initFacebookSdk = () => {
    // return new Promise((resolve, reject) => {
    //   // Load the Facebook SDK asynchronously
    //   !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //     n.queue=[];t=b.createElement(e);t.async=!0;
    //     t.src=v;s=b.getElementsByTagName(e)[0];
    //     //3317600655122580
    //     s.parentNode.insertBefore(t,s)}(window, document,'script',
    //     'https://connect.facebook.net/en_US/fbevents.js');
    //     fbq('init', '1355503575108582');
    //     fbq('track', 'PageView');
        
    //       window.fbAsyncInit = function() {
    //         FB.init({
    //           appId      : '1355503575108582',
    //           xfbml      : true,
    //           version    : 'v17.0'
    //         });
    //         FB.AppEvents.logPageView();
    //       };
        
    //       (function(d, s, id){
    //          var js, fjs = d.getElementsByTagName(s)[0];
    //          if (d.getElementById(id)) {return;}
    //          js = d.createElement(s); js.id = id;
    //          js.src = "https://connect.facebook.net/en_US/sdk.js";
    //          fjs.parentNode.insertBefore(js, fjs);
    //        }(document, 'script', 'facebook-jssdk'));
    //       resolve();
          
    // });
    return null;
}
export const getFacebookLoginStatus = () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        resolve(response);
      });
    });
  };

export const fbLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.login((response) => {
        resolve(response)        
      },{scope:'email'})
    })
  }
    
