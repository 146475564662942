import React, {useState}  from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import logo from '../../assets/image/logo.png';
import user from "../../assets/image/input_field_user.png";
import PhoneInput from "react-phone-number-input"

const Forgetpassword = () => {
    let {userId} = useParams();
    const navigate = useNavigate()
    const [newpassword,setnewpassword]= useState('');
    const [mobile_flag,setmobile_flag]=useState(false)
    const [number,setnumber]=useState('')
    const [cnew,setcnew]= useState('');
    const [current,setcurrent]= useState('');
    const [alert,setalert]=useState('')
    const [passwordalert,setpasswordalert]=useState('')
    const [flag ,setflag]=useState(false)
    const [otpcheck,setotpcheck]=useState(false)
    const [otp,setotp]=useState('')
    const [own,setown]=useState(false)
    const { forgetpassword ,VerifyPasswordOtp, handleFacebookSignIn,handleGoogleSignIn,Reset,ChangePassword,ResetPassword} = useUserAuth();
    var regularExpression = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/);
    var pattern = new RegExp(/^[0-9\b]+$/);

    const handleuser =(e)=>{
        let email_val = e.target.value
        if(email_val !== ''){
            setcurrent(email_val)
            //setLogin(true)
            //setmsg("")
        }
        pattern.test(e.target.value) ?   setnumber('+91'+email_val): setmobile_flag(false);
        pattern.test(e.target.value) ?  setmobile_flag(true) : setmobile_flag(false) ;
        
     }

    const reset=async(e)=>{
        e.preventDefault();
        setflag(true)
        setpasswordalert('')
        if(otpcheck === true){
            const data = {
                mobile:number,
                otp:otp
            }
            console.log(data)
            const result =await  VerifyPasswordOtp(data)
            console.log(result)
            if(result.code === 651){
                setown(true)
            }
            setalert(result.message)
            
            
        }else{
            var data = {}
            !mobile_flag ? 
            data ={ email :current} : 
            data ={mobile:number}
            console.log(mobile_flag , data)
            const result = await forgetpassword(data)
            console.log(result)
            if(result.code  === 601){
                setotpcheck(true)
                //setmobile_flag(true)
            }
            setalert(result.message)
            setTimeout(()=>{
            if(result.error === false){
                //navigate('/')
            }
            },[3000])

        }
    }
    
    const change=async(e)=>{
        e.preventDefault()
        if( newpassword  === cnew){
            var data = {}
            !mobile_flag ? data ={ email :current , newPassword:newpassword, confirmPassword:cnew} : data ={mobile:number , newPassword:newpassword, confirmPassword:cnew}
            console.log(mobile_flag , data)
            const result = await ResetPassword(data)
            console.log(result)
            setalert(result.message)
            if(result.code === 550){
                navigate('/login')
            }
            
        }else{
            setalert('password not matching')
        }
        

    }
  return (
    <>
    <section className="login">
    <div className="logincontent left">
        <div className="content_area">
            <div className="content_inner_area">
                <a href="/registration" className="login_logo"><img src={logo} alt=""/></a>
                <ul>
                    <li>Watch any first 4 videos absolutely free</li>
                    <li>Unlimited HD streaming for prime users</li>
                    <li>Download and watch offline on android  IOS</li>
                </ul>
                <h3>Dont Have An Account?</h3>
                <div className="flip_btn_area">
                    <a href="/register" className="flip_btn">REGISTER NOW</a>
                </div>
            </div>
        </div>
    </div>
    <div className="login_form right">
        <div className="login_form_area">
            <div className="login_inner_area">
                <a href={"/account"} className="back_home"><span>Back</span></a>
                <div className="content_heading"><h2>Forget<span> Password</span></h2></div>
                {!own ? 
                <form onSubmit={reset}>
                {flag ? <p className="alert mt-2 p-0"> {alert}</p>:''}
                <div className="input_field_area">
                    {!mobile_flag ? 
                    <div className="input_field_col">
                        <span><img src={user} alt=""/></span>
                        <input required type="text" onChange={handleuser} placeholder="Enter Email Address or mobile number " />
                    </div>:
                    <>
                    <i className="fa fa-arrow-left mb-2" style={{'color':'#fff'}} onClick={()=>setmobile_flag(false)}></i>
                    <div className="input_field_col">
                        
                        <PhoneInput
                        international
                        style={{  'padding': '5px','border':'1px solid #fff'}}
                            defaultCountry="IN"
                            countryCallingCodeEditable={false}
                            value={number}
                            onChange={setnumber}
                            placeholder="Enter Phone Number"
                           required 
                        />
                        <br/>
                        {!otpcheck ? 
                        '':
                        <div className="input_field_col">
                        <span><img src={user} alt=""/></span>
                        <input required type="text" onChange={(e)=>setotp(e.target.value)} placeholder="Enter otp " />
                        </div>
                        }
                       
                    </div>
                    </>
                    }
                    
                    {!otpcheck ? 
                    <button className='btn login mt-2' type="submit">Get Link / OTP</button>:
                    <button className='btn login mt-2' type="submit" >Submit</button>
                    }
                    
                    <span className='small'>{passwordalert}</span>
                </div>
                </form>:
                <form onSubmit={change}>
                {flag ? <p className="alert mt-2 p-0"> {alert}</p>:''}
                <div className="input_field_area">
                    <div className="input_field_col">
                        <span><img src={user} alt=""/></span>
                        <input required type="password" onChange={(e)=>setnewpassword(e.target.value)} placeholder="enter New Password" />
                    </div>
                    <div className="input_field_col">
                        <span><img src={user} alt=""/></span>
                        <input required type="password" onChange={(e)=>setcnew(e.target.value)} placeholder="confirm password" />
                    </div>
                    <button className='btn login' type="submit" >Submit</button>
                </div>
                </form>
                }
                
                
                
                
            </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Forgetpassword