import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const apiKEY=process.env.REACT_APP_API_KEY;
const Instance = axios.create({
    baseURL: baseURL,
    headers:{
    "Content-Type": "application/json",
    "signature":"kWVK7yyA9i7NWo9tJpl6ZpZYvsk=",
    //kWVK7yyA9i7NWo9tJpl6ZpZYvsk=
    }
});
export default Instance;

