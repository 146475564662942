import React,{useEffect,useState} from 'react'
import { Link , useNavigate, useParams} from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import paytmlogo from '../../assets/Payment/paytm_logo.png'
import stripelogo from '../../assets/Payment/stripe_logo.png'
import upi from '../../assets/Payment/upi.png'
import card from '../../assets/Payment/card.jpg';
import postscribe from 'postscribe';
import { begin_checkout, checkout_paytm,checkout_stripe } from '../../context/Eventlogger';
const Listpaymentgateway = () => {
    //const stripePromise = loadStripe("pk_test_51JH0eESEJ6sxCptfWMekHdOFtyQ4IXzCmsK6CB2fAXU3dhzv3wlkOVBH8qqGO2PdCmdsQq4q6xL2q3aILLBR7cXX00BKkYkDzc");
    const stripePromise = loadStripe("pk_live_51JH0eESEJ6sxCptfrmfvMjCiZRDN316qiyIoHPkjy3gUjCxD1WIPNT05p3KkuLwLaLE9gOVnHNnirM2WMv6zN9xq00MvA1IH4g");
    const {allSubscriptions,createOrder}=useUserAuth()
    const {packageId} = useParams()
    const userId = localStorage.getItem('id')
    const [response,setresponse]=useState({})
    const [flag,setflag]=useState(false)
    const [orderId,setorderId]=useState('')
    const [handle_paytm,sethandle_paytm]=useState({})
    const [loader,setloader]=useState(false);
    const [option,setoption]=useState(false)
    const navigate = useNavigate()
    useEffect(()=>{
      if(!userId){
        navigate('/login')
      }
        const data = allSubscriptions.filter(x=> x.subscription._id === packageId)
        setresponse(data)
    },[])

      //for paytm creating js element 
      async function handleLoadScript(mode_selected,data) {
        const orderId = data.order_id;
        const txntoken = data.paytmToken;
        const amount = data.amount;
        postscribe('#upi', '<script src="https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/RABBIT83100137902764.js"></script>', {
          done: function() {
            if(window.Paytm && window.Paytm.CheckoutJS){
             
              //Add callback function to CheckoutJS onLoad function
              window.Paytm.CheckoutJS.onLoad(function  excecuteAfterCompleteLoad() {
                //Config
                var config = {
                  flow:"DEFAULT",
                  hidePaymodeLabel: false,
                  data:{
                    orderId:orderId,
                    amount:amount,
                    token:txntoken,
                    tokenType:"TXN_TOKEN"
                  },
                  merchant:{
                    mid:"RABBIT83100137902764"
                  },
                  handler: {
                    notifyMerchant: function(eventType, data) {
                      
                    }
                  }
                };
          
                //Create elements instance
                var elements = window.Paytm.CheckoutJS.elements(config);
                //Create card element
                if(mode_selected === 'CARD'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.CARD, {root: "#card", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'UPI'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.UPI, {root: "#upi", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'NB'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.NB, {root: "#netbanking", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'PAY_WITH_PAYTM'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.PAY_WITH_PAYTM, {root: "#paywithpaytm", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                if(mode_selected === 'SCAN_AND_PAY'){
                  var cardElement = elements.createElement(window.Paytm.CheckoutJS.ELEMENT_PAYMODE.SCAN_AND_PAY, {root: "#scanandpay", style: {bodyBackgroundColor: "#fff"}});
                  cardElement.invoke();
                }
                
                //Render element
                
              });
            }
            
          }})
      };

      //handling paytm callback (user-choice)
      const modechange=(mode)=>{
        //setmode(name
        handleLoadScript(mode,handle_paytm)
      }
      //callback - https://laltenstaging.xyz/capture/paytm/callback
      const manage=async (id,name)=>{
        setloader(true)
        const final  = await createOrder(response,id,name,packageId,userId)
        if(final.error === false){
          if(final.data.payment_gateway  === 'stripe'){
            setflag(true)
            setClientSecret(final.data.stripe.stripe_paymentIntent)
            setorderId(final.data.order_id)
            setloader(false)
          }else if(final.data.payment_gateway  === 'paytm'){
            setflag(true)
            sethandle_paytm(final.data)
            setoption(!option)
            setloader(false)
          }
          }
      }
      
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    

  return (
    <>
    <div className={ loader ? 'loader' : 'loader hide'}><div className='spin'/></div>
    {/* <div className='container payment'>
        {
          !option ?
          flag ? 
            <div className='row p-2'>
              
                 {clientSecret && (
                    <Elements className="mt-2" options={options} stripe={stripePromise}>
                    <CheckoutForm packageId={packageId}  orderId={!orderId?'':orderId}/>
                    </Elements>
                )}
                
               
            </div>
            :
            <div className='row'>
                
                <Link to={'/subscriptions/plans/'}><p className='er' style={{'color':'#fff'}}>Back <i className='fa fa-angle-right'></i></p></Link>
               
                  <div className="inner">
                      <div className='inner_child' onClick={()=> manage('stripe', 'stripe')}>
                        <img src={stripelogo} />
                        <p>Stripe is a payment services provider that lets merchants accept credit and debit cards or other payments.</p>
                      </div>
                      <div className='inner_child' onClick={()=> manage('paytm', 'paytm')}>
                        <img src={paytmlogo} />
                        <p>Paytm is a payment services provider that lets merchants accept UPI, Net Banking and other payments.</p>
                      </div>
                  </div>
        </div>
          :
          ''
        }
            


        {
          !option ? 
          ''
          :
          <div className='paytm_option'>
            <img src={paytmlogo}></img>
            <ul>
              {/*<li onClick={()=> modechange('CARD')}><i className='fa fa-credit-card'></i> CREDIT / DEBIT CARD </li>
              <div id="card"></div>}
              <li onClick={()=> modechange('CARD')}><img src={card}/>CARD </li>
              <div id="card"></div>
              <li onClick={()=> modechange('UPI')}><img src={upi}/>UPI </li>
              <div id="upi"></div>
              <li onClick={()=> modechange('PAY_WITH_PAYTM')}><i className='fa fa-credit-card'></i> PAY WITH PAYTM</li>
              <div id="paywithpaytm"></div>
              <li onClick={()=> modechange('NB')}><i className='fa fa-university'></i> NET BANKING</li>
              <div id="netbanking"></div>
            </ul>
            
          </div>
        }
    </div> */}
    </>
  )
}

export default Listpaymentgateway