import React, { useState,useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthContext'

const Listpackage = () => {
    const { allSubscriptions,Check_subscription,setCountryCode,getPackages} = useUserAuth();
    const [countrycode , setCountrycode]=useState('IN')
    const [data,setData]=useState([])
    const [loader,setloader]=useState(true);
    const loadData=async()=>{
        let res = await getPackages();
        setData(res.data.data)
        setloader(false)
    }
    useEffect(()=>{
       // Check_subscription()
       setCountryCode()
       loadData()
        
    },[])
  return (
    <>
      <div className={loader ? "loader" : "loader hide"}>
        <div className="spin" />
      </div>
    <section class="inner_subcribe subs">

        <div class="inner_subcribe_area">
            <div class="subscribe_heading">
                <h2>Subscribe And Enjoy </h2>
            </div>
            <ul class="subscribe_info">
                <li>Unlimited Access and HD Streaming</li>
                <li>Download to watch offline on android and IOS</li>
                <li>Exclusive multi regional content</li>
            </ul>
            <div class="membership">
                <h2>Choose  a plan for becoming<span> Prime member</span></h2>
                <ul>
                    {data.map(x=>{
                            return (
                                <li>
                                    <div class="membership_col">
                                        <h4> <span><img src="assets/img/price_symbol.png" alt=""/></span> Rs. {x.price[0].value / 100 }</h4>
                                        {x.benefits.map((z,index)=>{
                                            return(
                                                <p key={index}>{z.benifitsName}</p>
                                            )
                                        })}
                                        <h5>{x.period} <br/><span>Days</span></h5>
                                        
                                        <a href={'/subscriptions/payments/modes/active/'+ x._id}><button className='btn_buy'> Buy Now</button> </a>
                                    </div>
                                </li>
                            )  
                    })}
                   
                    
                </ul>
            </div>
        </div>
    
</section>
</>
  )
}

export default Listpackage