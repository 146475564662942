import React,{useEffect,useState} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import {Access} from '../../services/Access'
const Success = () => {
  let navigate = useNavigate()
  const {paymentId,orderId} = useParams()
  const userId = localStorage.getItem('id')
  const [confirm,setconfirm]=useState({message:'',data:{subscription_enddate:''}})
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  
  const [timer,settimer]=useState( 10 )

  useEffect(()=>{
    //console.log(paymentId,orderId,clientSecret)
    const data= {
      'paymentId': paymentId,
      'orderId':orderId,
      'userId':userId
    }
    Access.post('/updateOrder',data)
    .then(res=>{
      console.log(res) //null for stripe 
    })

    setTimeout(()=>{
     confirmOrder(orderId,userId)
    },[3000])

  },[])

  const confirmOrder=(orderId,userId)=>{
    const param = {
      'orderId':orderId,
      'userId':userId
    }
    Access.post('/confirmOrder',param)
    .then(res=>{
      setconfirm(res.data)
      epoch_to_normal(res.data.data.subscription_enddate)
      setTimeout(()=>{
        navigate("/account")
      },[10000])
    })
  }


  const[date,setdate]=useState('')
  const epoch_to_normal=(time)=>{
    const daten = new Date(time)
    setdate(daten.toLocaleDateString() )

  }

  return (
    <div className='container success'>
    <h1>Thanks for your order! {orderId} </h1>
    
      Payment Successfull !
      If you have any questions, please email
      <a href="mailto:vooviapp@gmail.com"> vooviapp@gmail.com</a>.

      <p>{confirm.message}</p>
      <p>{date}</p>
    

    <p className="mt-3" style={{'color':'red'}}>Please wait ! you will be redirected. if not <a href={"/my-profile/"+userId}>click here</a></p>
    </div>
  )
}

export default Success