import React from 'react'

const Termsandcondition = () => {
  return (
    <>
    <section className="policy">
        <div className="container">
            <div className="policy_area">
                <div className="policy_area_col">
                    <div class="policy_heading"><h2 align="center">Terms & Conditions</h2></div>
                
                    <p>
                        This Website <a href="https://www.kadduapp.in">www.kadduapp.in</a> is
                        operated by <span className="text-bold text-brand">Tush Entertainment(OPC) Pvt Ltd</span> and owned by <span
                            className="text-bold text-brand">Kaddu</span> . Throwout the site,the terms
                        we, us and our Refer to <span className="text-bold text-brand">Kaddu</span>offers
                        this website,including all information,tools and services available form this site to you,
                        the uer,condition upon acceptance of all terms,conditions,policies and notices stated here.
                    </p>
                    <br/>
                    <br/>
                    
                    <h2 style={{'text-align':'center','color':'red','padding':' 20px 10px;', 'fontSize':'20px'}}><strong>BY ACCESSING <span className="text-bold text-brand">Kaddu</span>
                            WEB SITE
                            AND OR APP YOU AGREE TO BE BOUND BY THESE TERMS</strong></h2>
                    <p>
                        <strong>By visiting our site and/ or subscribing Packages from us,</strong> you engage in our
                        Service and agree to be bound by the following terms and conditions (Terms of Service, Terms),
                        including those additional terms and conditions and policies referenced herein and/or available
                        by
                        hyperlink. These Terms of Service apply to all users of the site, including without limitation
                        users
                        who are browsers, vendors, customers, merchants, and/ or contributors of content.
                        <br/><br/>
                        <strong>Please read these Terms of Service carefully</strong> before accessing or using our
                        website/apps. By accessing or using any part of the site, you agree to be bound by these Terms
                        of
                        Service. If you do not agree to all the Terms of Service, then you may not access the website or
                        use
                        any services. If these Terms of Service are considered an offer, acceptance is expressly limited
                        to
                        these Terms of Service.
                        <br/><br/>
                        <strong>Any new features or packages which are added</strong> to the current Package shall also
                        be
                        subject to the Terms of Service. You can review the most current version of the Terms of Service
                        at
                        any time on this page.
                        <br/><br/>
                        <strong>We reserve the right to update,</strong> change or replace any part of these Terms of
                        Service by posting updates and/or changes to our website.
                        <br/><br/>
                        <strong>It is your responsibility to check this page</strong> periodically for changes. Your
                        continued use of or access to the website following the posting of any changes constitutes
                        acceptance of those changes.
                        <br/><br/>
                        <strong>All the Terms should apply to all the websites,</strong> web pages, digital platforms,
                        interactive features, applications, widgets, blogs, social networks, etc., alternative reality
                        worlds or features, or other online or wireless offerings that post a link to these Terms,
                        whether accessed via computer, mobile device or other technology, manner or means.
                        <br/><br/>
                        You <strong>"use"</strong> Each Site or APP every time you access our Packages via computer,
                        mobile device or other devices, etc., view, link to or from, or otherwise interact or
                        communicate with
                        or connect to, the Each Site or App or any parts thereof or interact or communicate with other
                        users
                        through the Each Site or App including, without limitation, on message boards, chat rooms and/or
                        other communities established on Site or App.
                        <br/><br/>
                        <strong>Your use of the Each Site or App or any part thereof, signifies</strong> your agreement
                        to be bound by these Terms and the privacy policy (the "Privacy Policy"), which is hereby
                        incorporated by this reference into these Terms.
                        <br/><br/>
                        Considering that it is the <strong>nature of the internet that,</strong> even though the Each
                        Site or App is/ are ordinarily but not always targeted to Indian residents, it may be accessed
                        in
                        other parts of the world.
                        <strong>If you are not an Indian resident</strong> and yet use the Each Site or App, you
                        acknowledge, understand and agree that you are doing so on your own initiative and at your own
                        risk and that it is your responsibility and not ours to make sure that your use of the Each Site
                        or
                        App complies with all applicable local laws in the jurisdiction from where you access or use the
                        Each Site or App.
                        </p>
                </div>
                <div className="policy_area_col">
                        
                            <p>
                                IF YOU ARE ACCESSING EACH SITE OR APP, SUBMITTING YOUR PERSONALLY IDENTIFIABLE
                                INFORMATION ON THE SITE OR APP, YOU EXPRESSLY CONSENT TO THE TRANSFER OF SUCH DATA TO INDIA, AND
                                TO THE
                                PROCESSING OF SUCH DATA BY <span className="text-bold text-brand">Kaddu</span>'S
                                OR ITS DESIGNATED VENDORS SERVERS, WHERE YOUR DATA WILL BE GOVERNED BY RESPECTIVE LAWS THAT MAY
                                PROVIDE A LOWER OR DIFFERENT, LEVEL OF DATA PROTECTION THAN YOUR COUNTRY.
                            </p>

                            <div className="policy_area_col">
                            <div class="policy_heading">
                                <h2>Age of Majority</h2>
                                </div>
                            <p>
                                By agreeing to these Terms of Service, you represent that you are at least the age of majority
                                in
                                your state or province of residence, or that you are the age of majority in your state or
                                province of residence and you have given us your consent to allow any of your minor dependents
                                to use
                                this site.</p>
                            </div>
                            <div className="policy_area_col">
                            <div class="policy_heading">
                            <h2>MINORS</h2>
                            </div>
                            <p>
                                You must be 18 years of age or older to subscribe to our packages. In certain jurisdictions, the
                                age
                                of majority may be older than 18, in which case, you must satisfy that age in order to become a
                                member. While individuals under the age of 18 may utilize the service, they may do so only with
                                the
                                involvement, supervision, and approval of a parent or legal guardian.
                            </p>
                            </div>

                            <div className="policy_area_col">
                            <div class="policy_heading">
                            <h2>FAMILY FILTER</h2>
                            </div>
                            <p>Kaddu platform including android, ios and websites application may contains movies and webseries with age 
                                restricted content which cannot be seen by children under certain maturity group, An adults using the Kaddu 
                                platform in his/her phone can use family filter to filter out age restricted content and make sure an adult 
                                should use functionality wisely before handing over Phone to a children.
                            </p>
                            </div>

                            <div className="policy_area_col">
                            <div class="policy_heading">
                            <h2>PARENTAL LOCK</h2>
                            </div>
                            <p>Kaddu platform including android, ios and websites application may contains movies and webseries with age restricted 
                                content which cannot be seen by children under certain maturity group, a An adults using the Kaddu platform can set 
                                a parental lock from the application.
                            </p>
                            </div>

                             <div className="policy_area_col">
                            <div className="policy_heading">
                            <h2>OWNERSHIP & RESTRICTIONS OF USE</h2>
                            </div>
                            <p>
                                <strong>We reserve the right to refuse service to anyone for any reason at any time.</strong>
                                <br/>
                                You understand that your contents - not including <strong>credit card information,</strong> may
                                be
                                transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to
                                conform and adapt to technical requirements of connecting networks or devices. Credit card
                                information is always encrypted during transfer over networks.
                                <br/>
                                <strong>You agree not to copy, reproduce, duplicate, stream, capture, access through
                                    technology</strong> or means other than those provided on Each Site or App, perform,
                                transfer,
                                sell, resell, download, upload, archive, license to others, edit, modify, manipulate, create
                                derivative works from or based upon including, without limitation, mash-ups, montages,
                                wallpapers,
                                ringtones, greeting cards, T-shirts or other merchandise, publish, republish, post, transmit,
                                publicly display, frame, link from or to, distribute, share, embed, translate, decompile,
                                reverse
                                engineer, incorporate into any hardware or software application, use for commercial purposes, or
                                otherwise use or exploit the Each Site or App or any component part thereof. Any of the
                                unauthorized
                                uses referred to above would constitute an infringement of the copyrights and other proprietary
                                rights of <span className="text-bold text-brand">Kaddu</span> and/or its licensors
                                and a
                                violation of these Terms and may subject you to civil and/or criminal liability under applicable
                                laws.
                                <br/><br/>
                                <strong>You further agree not use our packages</strong> for any illegal or unauthorized purpose
                                nor
                                may you, in the use of the Service, violate any laws in your jurisdiction including but not
                                limited
                                to copyright laws. You must not transmit any worms or viruses or any code of a destructive
                                nature. A
                                breach or violation of any of the Terms will result in an immediate termination of your
                                Services.
                                <br/><br/>
                                The content of the Each Site or App and all copyrights, patents, trademarks, service marks,
                                trade
                                names and all other intellectual property rights therein are owned by <span
                                    className="text-bold text-brand">Tush Entertainment</span> and/or its licensors or group
                                companies and are protected by applicable Indian and international copyright and other
                                intellectual
                                property laws. You acknowledge, understand and agree that you shall not have, nor be entitled to
                                claim, any rights in and to the Each Site or App content/ Services and/or any portion thereof.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                                    <h2>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>
                                </div>
                            <p>
                                We are <strong>not responsible if information made available on this site is not accurate,
                                    complete
                                    or current.</strong> The material on this site is provided for general information only and
                                should not be relied upon or used as the sole basis for making decisions without consulting
                                primary,
                                more accurate, more complete or more timely sources of information. Any reliance on the material
                                on
                                this site is at your own risk.
                                <br/>
                                This site may contain certain historical information. Historical information, necessarily, is
                                not
                                current and is provided for your reference only. We reserve the right to modify the contents of
                                this
                                site at any time, but we have no obligation to update any information on our site. You agree
                                that it
                                is your responsibility to monitor changes to our site.
                            </p>
                            </div>

                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>MODIFICATIONS TO THE SERVICE AND PRICES</h2>
                            </div>
                            <p>
                                Prices for our packages are subject to change without notice. We reserve the right at any time
                                to
                                modify or discontinue the Service (or any part or content thereof) without notice at any time.

                                We shall not be liable to you or to any third-party for any modification, price change,
                                suspension
                                or discontinuance of the Service.
                            </p>
                            </div>

                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>REGISTRATION</h2>
                            </div>
                            <p>
                                To register for the <span className="text-bold text-brand">Kaddu</span> Each Site or App Service
                                you
                                shall be required to do the following:
                                <strong>Open an account</strong> by completing the registration process by providing us with
                                current, complete and accurate information as prompted by the applicable registration form; also
                                will choose a password and a user name.
                                <br/><br/>
                                You are entirely responsible for <strong>maintaining the confidentiality</strong> of your
                                password
                                and account. In particular, as a parent or legal guardian, you acknowledge and assume sole
                                responsibility to ensure that content which is meant for mature audiences, i.e., above the age
                                of
                                majority, is not accessed by children. Hence, you may not share your log in credentials with
                                your
                                children.
                                <br/><br/>
                                You expressly agree to absolve <span className="text-bold text-brand">Kaddu's </span> Each Site or
                                App/Service of any responsibility / liability in this regard.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>SUBSCRIPTION</h2>
                            </div>
                            <p>
                                To be able to view certain premium content (Premium Content), you will be required to subscribe
                                to
                                any one subscription plan (Plan) of the <span className="text-bold text-brand">Kaddu</span> Each
                                Site
                                or App/Service viz., the plans available on the Each Site or App to be able to access the
                                services
                                which shall be entitled to full fee flexibility, solely at the discretion of <span
                                    className="text-bold text-brand">Kaddu</span>.
                                <br/><br/>
                                You may be required to subscribe to the <span className="text-bold text-brand">Kaddu's</span>Each
                                Site
                                or App such subscription in a particular <strong>geographical territory</strong> that shall be
                                valid
                                for that territory only and shall not automatically entitle you to access your account from a
                                different geographical territory, unless specifically permitted by <span
                                    className="text-bold text-brand">Kaddu</span> You will be required to
                                re-subscribe to the <span className="text-bold text-brand">Kaddus's</span> Each Site or App in the
                                event
                                that you wish to use the services from a different territory other than the one from where you
                                originally subscribed to the <span className="text-bold text-brand">Kaddu's</span> Each Site or
                                App.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>ACCESS TO USE</h2>
                            </div>
                            <p>
                                To access the <span className="text-bold text-brand">Kaddu's</span> Each Site or App, you will be
                                asked
                                to enter your individual user name and password, as chosen by you during your registration.
                                <br/><br/>
                                If <span className="text-bold text-brand">Kaddu's</span> reasonably believes that an account and
                                password is being used / misused in any manner, <span className="text-bold text-brand">Kaddu</span>
                                reserves the right to cancel access rights immediately without notice, and block access to all
                                users
                                from that IP address. Furthermore, you shall be entirely responsible for any and all activities
                                that
                                occur under your account. You agree to notify <span className="text-bold text-brand">Kaddu</span>
                                immediately of any unauthorized use of your account or any other breach of security. <span
                                    className="text-bold text-brand">Kaddu</span> will not be liable for any loss
                                that you may incur as a result of someone else using your password or account, however, you
                                could be
                                held liable for losses incurred by <span className="text-bold text-brand">Kaddu</span>
                                or another party due to someone else using your account or password.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>AVAILABILITY & QUALITY</h2>
                            </div>
                            <p>
                                The availability of content(s) to view through <span
                                    className="text-bold text-brand">Kaddu's</span>
                                Each Site or App will change from time to time at the sole discretion of <span
                                    className="text-bold text-brand">Kaddu</span>. <strong>The quality of the
                                    display</strong> of the streaming video may vary from computer to computer, and device to
                                device, and may be affected by a variety of factors, such as your location, the bandwidth
                                available
                                through and/or speed of your internet connection, and/ or quality of user's hardware.
                                <strong>You
                                    are responsible for all internet access charges.</strong> Please check with your internet
                                provider for information on possible internet data usage charges.
                                <br/><br/>
                                <strong>Geographic Limitation:</strong> You may watch a video through the <span
                                    className="text-bold text-brand">Kaddu's</span> Each Site or App only in geographic locations
                                where <span className="text-bold text-brand">Kaddu application</span> are releasing Each Site or
                                App.
                                The content that may be available to view will vary by geographic location. <span
                                    className="text-bold text-brand">Kaddu</span> may use technologies to verify
                                your geographic location.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2> ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
                            </div>
                            <p>
                                We reserve <strong>the right to refuse to subscribe our packages.</strong> We may, in our sole
                                discretion, limit or cancel subscription per person, per household. These restrictions may
                                include
                                subscriptions by the same customer account, the same credit card, and/or subscriptions that use
                                the
                                same address. In the event that we make a change to or cancel the subscription, we may attempt
                                to
                                notify you by contacting the e-mail and/or billing address/phone number provided at the time of
                                subscription. We reserve the right to limit or prohibit subscriptions that, in our sole
                                judgment,
                                appear to be subscribed by malicious intentions.
                                <br/><br/>
                                <strong>You agree to provide current, complete and accurate account information for all
                                    subscriptions.</strong> You agree to promptly update your account and other information,
                                including your email address and credit card numbers and expiration dates, so that we can
                                complete
                                your transactions and contact you as needed.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2> OPTIONAL TOOLS</h2>
                            </div>
                            <p>
                                We may not provide you with access to third-party tools over which we monitor nor have any
                                controls
                                or input.
                                <br/><br/>
                                You acknowledge and agree that we provide access to such tools as is and as available without
                                any
                                warranties, representations or conditions of any kind and without any endorsement. We shall have
                                no
                                <strong>liability whatsoever arising </strong>from or relating to your use of optional
                                third-party
                                tools.
                                <br/><br/>
                                <strong>Any use by you of optional tools offered through the site is entirely at your own
                                    risk </strong>and discretion and you should ensure that you are familiar with and approve of
                                the
                                terms on which tools are provided by the relevant third-party provider(s).
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>THIRD-PARTY LINKS</h2>
                            </div>
                            <p>
                                Certain content, products and services available via our Service may <strong>include materials
                                    from
                                    third-parties.</strong>
                                <br/><br/>
                                <strong>Third-party links on this site</strong> may direct you to third-party websites that are
                                not
                                affiliated with us. We are not responsible for examining or evaluating the content or accuracy
                                and
                                we do not warrant and will not have any liability or responsibility for any third-party
                                materials or
                                websites, or for any other materials, products, or services of third-parties.
                                <br/><br/>
                                <strong>We are not liable for any harm or damages </strong> related to the subscriptions or use
                                of
                                services, resources, content, or any other transactions made in connection with any third-party
                                websites. Please review carefully the third-party's policies and practices and make sure you
                                understand them before you engage in any transaction. Complaints, claims, concerns, or questions
                                regarding third-party products should be directed to the third-party.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
                            </div>
                            <p>
                                If, at our request, you send certain specific submissions (for example contest entries) or
                                without a
                                request from us you send creative ideas, suggestions, proposals, plans, or other materials,
                                whether
                                online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we
                                may, at
                                any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in
                                any
                                medium any comments that you forward to us. We are and shall be under no obligation <br/>(1) to
                                maintain
                                any comments in confidence, <br/>(2) to pay compensation for any comments or,<br/> (3) to respond
                                to any
                                comments.
                                <br/><br/>
                                We may, but have <strong>no obligation to, monitor, edit or remove content</strong> that we
                                determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory,
                                pornographic, obscene or otherwise objectionable or violates <strong>any party's intellectual
                                    property or these Terms of Service.</strong>
                                <br/><br/>
                                <strong>You will not interfere with or disrupt,</strong> or attempt to interfere with or
                                disrupt,
                                the operation of the Each Site or App or any parts thereof.
                                <br/><br/>
                                <strong>You will not remove, alter, interfere with or circumvent any digital rights
                                </strong>management
                                mechanism, device or other content protection or access control measure including, without
                                limitation geo-filtering and/or encryption, etc., associated with Site content.
                                <br/><br/>
                                <strong>You agree that your comments will not violate any right of</strong> any third-party,
                                including copyright, trademark, privacy, personality or other personal or proprietary right.
                                <br/><br/>
                                You further agree that your comments will not contain <strong>defamatory otherwise unlawful,
                                    abusive
                                    or obscene material, or contain any computer virus or other malware </strong>that could in
                                any
                                way affect the operation of the Service or any related website.
                                <br/><br/>
                                You may not use a false e-mail address, <strong>pretend to be someone other than
                                    yourself</strong>,
                                or otherwise mislead us or third-parties as to the origin of any comments. You are solely
                                responsible for any comments you make and their accuracy. We take no responsibility and assume
                                no
                                liability for any comments posted by you or any third-party.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>PERSONAL INFORMATION</h2>
                            </div>
                            <p>
                                Your submission of personal information through the subscription is governed by our Privacy
                                Policy.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>ERRORS, INACCURACIES AND OMISSIONS</h2>
                            </div>
                            <p>
                                Occasionally there may be information on our site or in the Service that contains
                                <strong>typographical
                                    errors,</strong> inaccuracies or omissions that may relate to product descriptions, pricing,
                                promotions, offers, product shipping charges, transit times and availability.<strong> We reserve
                                    the
                                    right to correct any errors,</strong> inaccuracies or omissions, and to change or update
                                information or <strong> cancel subscription </strong>if any information in the Service or on any
                                related website is inaccurate at any time without prior notice, including after you have
                                subscribed
                                the packages.
                                <br/><br/>
                                We undertake no obligation to update, amend or clarify information in the Service or on any
                                related
                                website, including without limitation, pricing information, except as required by law. No
                                specified
                                update or refresh date applied in the Service or on any related website should be taken to
                                indicate
                                that all information in the Service or on any related website has been modified or updated.
                            </p>
                            </div>
                            
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2> PROHIBITED USES</h2>
                            </div>
                            <p style={{'text-align':'left'}}>
                                In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from
                                using the site or its content:
                                <br/>
                                <br/>&emsp;(a) &emsp; for any unlawful purpose;
                                <br/>&emsp;(b) &emsp; to solicit others to perform or participate in any unlawful acts;
                                <br/>&emsp;(c) &emsp; to violate any international, federal, provincial or state regulations,
                                rules,
                                laws, or local ordinances;
                                <br/>&emsp;(d) &emsp; to infringe upon or violate our intellectual property rights or the
                                intellectual property rights of others;
                                <br/>&emsp;(e) &emsp; to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
                                discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national
                                origin,
                                or disability;
                                <br/>&emsp;(f) &emsp; to submit false or misleading information;
                                <br/>&emsp;(g) &emsp; to upload or transmit viruses or any other type of malicious code that will
                                or
                                may be used in any way that will affect the functionality or operation of the Service or of any
                                related website, other websites, or the Internet;
                                <br/>&emsp;(h) &emsp; to collect or track the personal information of others;
                                <br/>&emsp;(i) &emsp; to spam, phish, harm, pretext, spider, crawl, or scrape;
                                <br/>&emsp;(j) &emsp; for any obscene or immoral purpose; or
                                <br/>&emsp;(k) &emsp; to interfere with or circumvent the security features of the Service or any
                                related website, other websites, or the Internet. We reserve the right to terminate your use of
                                the
                                Service or any related website for violating any of the prohibited uses.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2><strong>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</strong></h2>
                            </div>
                            <p>
                                <strong>We do not guarantee, represent or warrant</strong> that your use of our service will be
                                uninterrupted, timely, secure or error-free.
                                <br/><br/>
                                <strong>We do not warrant </strong>that the results that may be obtained from the use of the
                                service
                                will be accurate or reliable.
                                <br/><br/>
                                You agree that <strong>from time to time we may remove the service for indefinite
                                    periods</strong>
                                of time or cancel the service at any time, without notice to you.
                                <br/><br/>
                                You expressly agree that your use of, <strong>or inability to use, the service is at your sole
                                    risk.</strong> The Packages offered to you through the service are (except as expressly
                                stated
                                by us) provided 'as is' and 'as available' for your use, without any representation, warranties
                                or
                                conditions of any kind, either express or implied.
                                <br/><br/>
                                In no case shall <span className="text-bold text-brand">Kaddu</span> our directors, officers,
                                employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors
                                be
                                liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or
                                consequential damages of any kind, including, without limitation lost profits, lost revenue,
                                lost
                                savings, loss of data, replacement costs, or any similar damages, whether based in contract,
                                tort
                                (including negligence), strict liability or otherwise, arising from your use of any of the
                                service
                                or any packages procured using the service, or for any other claim related in any way to your
                                use of
                                the service or any package, including, but not limited to, any errors or omissions in any
                                content,
                                or any loss or damage of any kind incurred as a result of the use of the service or any content
                                posted, transmitted, or otherwise made available via the service, even if advised of their
                                possibility. Because some states or jurisdictions do not allow the exclusion or the limitation
                                of
                                liability for consequential or incidental damages, in such states or jurisdictions, our
                                liability
                                shall be limited to the maximum extent permitted by law.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>INDEMNIFICATION</h2>
                            </div>
                            <p>
                                <strong>You agree to indemnify, defend and hold harmless <span
                                        className="text-bold text-brand">Kaddu</span></strong>
                                and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors,
                                licensors, service providers, subcontractors, suppliers, interns and employees, harmless from
                                any
                                claim or demand, including reasonable lawyers fees, made by any third-party due to or arising
                                out of
                                your breach of these Terms of Service or the documents they incorporate by reference, or your
                                violation of any law or the rights of a third-party.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>SEVER ABILITY</h2>
                            </div>
                            <p>
                                In the event that <strong>any provision of these Terms of Service is determined to be unlawful,
                                    void
                                    or unenforceable,</strong> such provision shall nonetheless be enforceable to the fullest
                                extent
                                permitted by applicable law, and unenforceable portion shall be deemed to be severed from these
                                Terms of Service, such determination shall not affect validity and enforceability of any other
                                remaining provisions.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>TERMINATION</h2>
                            </div>
                            <p>
                                These Terms of Service are effective unless and until terminated by either you or us. <strong>
                                    You
                                    may terminate these Terms of Service</strong> at any time by notifying us that you no longer
                                wish to use our Services, or when you cease using our site.
                                If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or
                                provision of these Terms of Service, <strong>we also may terminate this agreement</strong> at
                                any
                                time without notice and you will remain liable for all amounts due up to and including the date
                                of
                                termination; and/or accordingly may deny you access to our Services (or any part thereof).
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>GOVERNING LAW</h2>
                            </div>
                            <p>
                                These Terms of Service and any separate agreements whereby we provide you Services shall be
                                governed
                                by and construed in accordance with <strong> the laws of India and jurisdiction of Mumbai,
                                    Maharashtra.</strong>
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>CHANGES TO TERMS OF SERVICE</h2>
                            </div>
                            <p>
                                You can review the most current version of the Terms of Service at any time at this page. We
                                reserve
                                the right, at our sole discretion, to update, change or replace any part of these Terms of
                                Service
                                by posting updates and changes to our website. It is your responsibility to check our website
                                periodically for changes. Your continued use of or access to our website or the Service
                                following
                                the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>COPYRIGHT INFRINGEMENT NOTIFICATION</h2>
                            </div>
                            <p >
                                <span className="text-bold text-brand">Kaddu</span> respects <strong>the
                                    intellectual
                                    property rights of others.</strong> Upon proper notice, <span
                                    className="text-bold text-brand">Kaddu</span>
                                will remove User Submissions (and any other Each Site or App content) that violates copyright
                                law.
                                As per the provisions of Information Technology Act, 2000 and any amendments made thereto along
                                with
                                the rules framed there under and the provisions of the Copyright Act, 1957 along with all the
                                amendments made thereto and rules framed there under, <span className="text-bold text-brand">Kaddu</span>
                                shall remove upon receiving written notification of claimed copyright infringement on the Each
                                Site
                                or App and for processing such claims in accordance with such law.
                                <br/>
                                If you believe <strong>a work has been copied in a way that constitutes copyright
                                    infringement,</strong> please send a notification of claimed infringement (an "Infringement
                                Notification") that contains all the following information:<br/>(i) identification of the
                                copyrighted
                                work claimed to have been infringed.<br/>(ii) identification of the claimed infringing material
                                and
                                information reasonably sufficient to permit <span className="text-bold text-brand">Kaddu</span> to locate the material on Each
                                Site or App.<br/> (iii) information reasonably sufficient to permit <span
                                    className="text-bold text-brand">Kaddu</span>
                                to contact you, such as an address, telephone number, and, if available, an e-mail
                                address.<br/>(iv)
                                a
                                statement by you that you have a good faith belief that the disputed use is not authorized by
                                the
                                copyright owner, its agent, or the law.<br/>(v) a statement by you, made under penalty of
                                perjury,
                                that
                                the above information in your notification is accurate and that you are the copyright owner or
                                authorized to act on the copyright owner's behalf and<br/>(vi) your physical signature.
                                <br/><br/>
                                By submitting an Infringement Notification, you acknowledge and agree that <span
                                    className="text-bold text-brand">Kaddu</span>may forward your Infringement
                                Notification and any related communications to any users who posted the material identified in
                                such
                                notice.
                                <br/><br/>
                                <strong>Infringement Notification is to be sent to</strong>
                                <br/>
                                <span className="text-bold text-brand"></span>Kaddu
                                <br/>
                                Kaddu Entertainment Pvt. Ltd.
                                257, Adarsh Nagar Behind Off Zubin wine Shop, New Link Road, Andheri West, Mumbai-400053.        
                                <br/>
                            </p>
                            </div>
                            <div className="policy_area_col">
                                <div className="policy_heading">
                            <h2>ENTIRE AGREEMENT</h2>
                            </div>
                            <p>
                                The failure of us to exercise or enforce any right or provision of these Terms of Service shall
                                not
                                constitute a waiver of such right or provision.
                                <br/><br/>
                                These Terms of Service and any policies or operating rules posted by us on this site or in
                                respect
                                to The Service constitutes the entire agreement and understanding between you and us and govern
                                your
                                use of the Service, superseding any prior or contemporaneous agreements, communications and
                                proposals, whether oral or written, between you and us (including, but not limited to, any prior
                                versions of the Terms of Service).
                                <br/><br/>
                                Any ambiguities in the interpretation of these Terms of Service shall not be construed against
                                the
                                drafting party.
                                <br/><br/>
                                The headings used in this agreement are included for convenience only and will not limit or
                                otherwise affect these Terms.
                            </p>
                            </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Termsandcondition