import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const apiKEY=process.env.REACT_APP_API_KEY;
var token, userId ; 
if(localStorage.getItem('access-token')){
    token = localStorage.getItem('access-token');
    userId = localStorage.getItem('id')
}

const Userinstance=axios.create({
    baseURL: baseURL,
    headers:{
    "userId":userId,
    "Content-Type":"multipart/form-data",
    "signature":"NzUhY2QzNTEtOGIwMy00Njf4LThiNGM=",
    "accesstoken": token
    }
});
export default Userinstance;
