import React from 'react'

const Privacypolicy = () => {
  return (
    <>
    <section class="policy">
    <div class="container">
        <div class="policy_area">
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>Privacy <span>Policy</span></h2>
                </div>
                <p>This Privacy Policy (Privacy Policy) applies to <b>Kaddu
                        PLATFORM'S</b>
                    Site/ App platforms including without limitation
                    <a href="mailto:kadduappwork@gmail.com"> kadduappwork@gmail.com</a>  and other related Site/s or App/s, mobile
                    applications and other online features each a Site/s or Apps.</p>
                <p>This Privacy Policy should be read in conjunction with the Terms of Use available on the Site/s
                    or App/s. Personal Information/Data defined below of a user/s is collected if the user/s
                    registers with the Site/s or App/s, accesses the Site/s or App/s or takes any action on the
                    Site/s or App/s or uses or accesses the Services as defined in the Terms of Use.
                    <br/>
                    The terms
                    Personal Information and Sensitive Personal Information or Information shall have the meaning
                    ascribed to it under the Indian Information Technology Act, 2000 read with the Information
                    Technology.
                </p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>WHAT DO WE DO WITH YOUR INFORMATION?</h2>
                </div>
                <p>When you subscribe something from our packages as part of the buying and selling process, we
                    collect
                    the personal information you give us such as your name, address and email address.
                    <br/>
                    When you browse our packages, we also automatically receive your computers internet protocol
                    (IP)
                    address in order to provide us with information that helps us learn about your browser and
                    operating
                    system.
                    <br/>
                    Email marketing (if applicable): With your permission, we may send you emails about our store,
                    new
                    products and other updates.
                </p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>CONSENT</h2>
                </div>
                <p>When you provide us with personal information to complete a transaction, verify your credit
                    card, we
                    imply that you consent to our collecting it and using it for that specific reason only.
                    <br/>
                    If we ask for your personal information for a secondary reason, like marketing, we will either
                    ask
                    you directly for your expressed consent, or provide you with an opportunity to say no.
                </p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>How do I withdraw my consent?</h2>
                </div>
                <p> If after you opt-in, you change your mind, you may withdraw your consent for us to contact you,
                    for
                    the continued collection, use or disclosure of your information, at anytime, by mailing us at 
                    <a href="mailto:kadduappwork@gmail.com"> kadduappwork@gmail.com</a>  or contacting us
                    at the address provided on website</p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>DISCLOSURE</h2>
                </div>
                <p> We may disclose your personal information if we are required by law to do so or if you violate
                    our
                    Terms of Services.</p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>PAYMENT</h2>
                </div>
                <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their
                    servers.
                    The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when
                    processing payment. Your purchase transaction data is only used as long as is necessary to
                    complete
                    your purchase transaction.
                    <br/>
                    After that is complete, your purchase transaction information is not saved.
    Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security
    Standards
    Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
    <br/>
    PCI-DSS requirements help ensure the secure handling of credit card information by our store and
    its
    service providers.
    <br/>
    For more insight, you may also want to read terms and conditions of Razor Pay on
    <a href="https://razorpay.com">
         https://razorpay.com </a>
                    </p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>THIRD-PARTY SERVICES</h2>
                </div>
                <p>In general, the third-party providers used by us will only collect, use and disclose your
                    information to the extent necessary to allow them to perform the services they provide to us.
                <br/>
                However, certain third-party service providers, such as payment gateways and other payment
    transaction processors, have their own privacy policies in respect to the information we are
    required to provide to them for your purchase-related transactions.
    <br/>
    For these providers, we recommend that you read their privacy policies so you can understand the
    manner in which your personal information will be handled by these providers.
    <br/>
    In particular, remember that certain providers may be located in or have facilities that are
    located
    a different jurisdiction than either you or us. So if you elect to proceed with a transaction
    that
    involves the services of a third-party service provider, then your information may become
    subject to
    the laws of the jurisdiction(s) in which that service provider or its facilities are
    located.
    <br/>
    Once you leave our store's website or are redirected to a third-party website or application,
    you
    are no longer governed by this Privacy Policy or our website's Terms of Service.
    <br/>
    When you click on links on our website for subscription, they may direct you away from our site.
    We
    are not responsible for the privacy practices of other sites and encourage you to read their
    privacy
    statements.
                </p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>SECURITY</h2>
                </div>
                <p>To protect your personal information, we take reasonable precautions and follow industry best
                    practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or
                    destroyed.</p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>COOKIES</h2>
                </div>
                <p> We use cookies to maintain session of user. It is not used to personally identify you on other
                    websites.</p>
            </div>
            <div class="policy_area_col">
                <div class="policy_heading">
                    <h2>AGE OF CONSENT</h2>
                </div>
                <p>By using this site, you represent that you are at least the age of majority in your state or
                    province of residence, or that you are the age of majority in your state or province of
                    residence
                    and you have given us your consent to allow any of your minor dependents to use this site.</p>
            </div>
<div class="policy_area_col">
    <div class="policy_heading">
        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
    </div>
    <p>We reserve the right to modify this privacy policy at any time, so please review it frequently.
        Changes and clarifications will take effect immediately upon their posting on the website. If we
        make material changes to this policy, we will notify you here that it has been updated,
        so that you are aware of what information we collect, how we use it, and under what
        circumstances,
        if any, we use and/or disclose it.
        <br/>
        If our APP is acquired or merged with another company, your information may be transferred to
        the
        new owners so that we may continue to provide services to you.
    </p>
</div>
<div class="policy_area_col">
    <div class="policy_heading">
        <h2> QUESTIONS AND CONTACT INFORMATION</h2>
    </div>
    <p>If you would like to: access, correct, amend or delete any personal information we have you,
        register a complaint, or simply want more information mail our Privacy Compliance Officer at 
            <a href="mailto:kadduappwork@gmail.com"> kadduappwork@gmail.com </a> 
        or by contacting at the company contact which is hereby incorporated by this reference into the
        Terms of Service.</p>
</div>
<div class="policy_area_col">
    <div class="policy_heading">
        <h2>REFUND POLICY</h2>
    </div>
    <p>We being the service providers for contents available through SITE or APP, where you are
        expected to
        view packages of your choice after being paid for subscription.<strong>unfortunately</strong>
        all
        fees to Kaddu for such services are non-refundable.
        <br/>
        In case, because of technical glitch at the time of online transaction, the transaction does not
        occur, the amount in process of transfer <strong>by default goes back in your bank account,</strong>
        automatically through Payment Gateway.
    </p>
</div>
<div class="policy_area_col">
    <div class="policy_heading">
        <h2>CHARGEBACKS</h2>
    </div>
    <p>If we receive a chargeback or payment dispute from a credit card company or bank, your service
        and/or subscription will be suspended without notice. Applicable chargeback fee will be issued
        to
        recover fees passed on to us by the credit company, plus any outstanding balances accrued as a
        result of the chargeback(s) must be paid in full before service is restored.
        <br/>
        Instead of issuing a chargeback, contact us to address any billing issues. Requesting a
        chargeback
        or opening any sort of dispute for a valid charge from us is fraud, and is never an appropriate
        or
        legal means of obtaining a refund.
    </p>
</div>
<div class="policy_area_col">
    <div class="policy_heading">
        <h2>AGE OF CONSENT</h2>
    </div>
    <p>By using this site, you represent that you are at least the age of majority in your state or
        province of residence, or that you are the age of majority in your state or province of
        residence
        and you have given us your consent to allow any of your minor dependents to use this site.</p>
</div>
<div class="policy_area_col">
<p>Please Read and make sure you fully understand our refund policy prior to makeing a payment</p>
<p class="text-brand"><i>If you need more information you can contact us at 
    <a href="mailto:kadduappwork@gmail.com"> kadduappwork@gmail.com</a> </i></p>
 </div>   
</div>
</div>
</section>
    </>
  )
}

export default Privacypolicy