import React,{useState} from 'react'
import Kaddu from "../../assets/image/logo.png"
import { deep_link } from '../../context/Eventlogger';
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
var isFirefox = typeof InstallTrigger !== 'undefined';
var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof isSafari !== 'undefined' && isSafari.pushNotification));
const Install = () => {
    const content=()=>{
        if(isSafari){
            return (
                <div className='install'>
                <i className='fa fa-close' onClick={hide}></i>
                <img src={Kaddu}></img>
                <p>Kaddu Movies -<br />
                <span>use Discount code Kaddu10 to get 10% off on 1 year plan</span><br/>
                <span className='icons'><i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i> 
                <i className='fa fa-star-o'></i>
                ( 10L+ )
                </span>
                </p>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.Kaddu.android.pro.release&hl=en_IN&gl=US" onClick={()=> deep_link('install')}>Install</a>
            </div>
            )
        } else {
            return (
                <div className='install'>
                <i className='fa fa-close' onClick={hide}></i>
                <img src={Kaddu}></img>
                <p>Kaddu Movies<br/>
                <span className='mt-2'>Download App to watch best Web Series, Movies shows and much more.</span><br/>
                <span className='icons mt-2'><i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star'></i>
                <i className='fa fa-star-o'></i>
                <span>( 10L+ )</span>
                </span>
                </p>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.Kaddu.android.pro.release&hl=en_IN&gl=US" onClick={()=> deep_link('install')}>Install</a>
            </div>
            )
        }
      }
      const [flag,setflag]=useState(false)
      const hide=()=>{
        setflag(!flag)
      }
  return (
    <>
    {!flag ? 
    content() :''}
    </>
  )
}

export default Install