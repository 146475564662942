import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams ,Link, Navigate, useNavigate} from 'react-router-dom'
import download1 from "../../assets/image/download_app_1.png"
import download2 from "../../assets/image/download_app_2.png"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useUserAuth } from '../../context/UserAuthContext';
const baseURL = process.env.REACT_APP_BASE_URL;

const Getuserdetails = () => {
  let {userId} = useParams();
  let navigate = useNavigate();
  const { logout,verifyqr,fetchuserdetails} = useUserAuth();
  const [col,setcollection]=useState({})
  const [flag,setflag]=useState(false)
  const [st,setst]=useState(false)
  const [end,setend]=useState('')
  const [spin,setspin]=useState(false)
  const [webosmsg,setwebosmsg]=useState('');
//  const us = localStorage.getItem('user')
  const [permission,setpermission]=useState('')
  const [password,setpassword]=useState(false)
  const search = new URLSearchParams(window.location.search)
  // const _id = search.get("id")
  // const callback = search.get("callback");
  // const token  = search.get("token");
  // const socketId  = search.get("socketId");
  // const accesstoken = search.get("accesstoken")
  // const localtoken = localStorage.getItem('access-token')
  useEffect(()=>{
    let userId = localStorage.getItem('id');
    setloader(true)
    const fetchData=async(userId)=>{
      if(userId !== ""){
        let res = await fetchuserdetails(userId);
        if(!res.data.error){
          let base = res.data.data;
          setcollection(base)
          if(base.subscription_type === "paid"){
            setflag(true)
            returnstart(base.subscription_startdate,base.subscription_enddate)
          }
        }else{
          logout();
        }
        setloader(false);
      }else{
        navigate('/login')
      }
    }
    fetchData(userId);
  },[])
  const returnstart=(start,end)=>{
    var date1 = new Date(start)
    var date2 = new Date(end)
    const map = date1.toLocaleDateString().split('/')
    const map_end = date2.toLocaleDateString().split('/')
    const real = map[0]+'/'+ map[1] +'/'+map[2]
    const real_end = map_end[0]+'/'+ map_end[1] +'/'+map_end[2]
    setst(real)
    setend(real_end)
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const [loader,setloader]=useState(false);
  const userlogout=()=>{
    setloader(true);
    logout();
    setTimeout(()=>{
      navigate('/login')
    },[2000])
  }
  return (
    <>
    <section className='getuser contactus one'>
    <div className={ loader ? 'loader' : 'loader hide'}><div className='spin'/></div>
    <div className="container">
    <h1>MY PROFILE</h1>
    
    <p className='username'>
    {permission === 'rabbit' ? <i className='fa fa-envelope' style={{'marginRight':'10px'}} />:''}
    {permission === 'google'? <i className='fa fa-google' style={{'marginRight':'10px'}} />:''}
    {permission === 'facebook' ? <i className='fa fa-facebook' style={{'marginRight':'10px'}} />:'' }
      
    { col.name}  
    {!password ? 
    '':<i className='fa fa-cog '  style={{'paddingLeft':'10px','fontSize':'18px','cursor':'pointer'}} onClick={handleClick}></i>}
    <br/>

    {col.email}</p>
    

    <Menu
      style={{'marginLeft':'35px','marginTop':'0px'}}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}><Link to={"/my-profile/update/resetpassword/"+ userId}>Change Password</Link></MenuItem>
      </Menu>


            {flag ?
            <>
              <p style={{'lineHeight':'50px'}}className='username'>Subscription Validity - {end} <br/> 
             
              </p>
            </>
            :
            <p className='username'>Account Type - Free <span className="upgrade"><a href="/subscriptions/plans/">Buy Plan</a></span></p>}
            <hr/>
            <h3>Download Our App</h3>
            <div className='images'>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.rabbit.android.pro.release&hl=en_IN&gl=US"><img src={download2}/></a>
            <a href="#"><img src={download1}/></a>
            </div>
            <p style={{'color':'red','fontSize':'16px'}}>{webosmsg}</p>
    <button onClick={()=> {userlogout()}}>LOGOUT</button>
    </div> 
    </section>
    </>
  )
}

export default Getuserdetails